<template>
    <div class="main intro-y box p-5 w-full">
      <div>
        <img v-if=" success === '1'" class="img" src="./授权成功@2x.png">
        <img v-else class="img" src="./授权失败@2x.png">
      </div>
      <div>
        <label class="text-1">{{ success === '1' ? '支付成功' : '支付失败' }}</label>
      </div>
      <p class="text-2">{{ success === '1' ? '恭喜您，您已支付成功，请关闭此页面' : '支付失败,请重新发起支付' }}</p>
      <el-button type="primary" size="mini" class="btn" @click="handleClose()">
        {{ success === '1' ? '关闭' : '返回' }}
      </el-button>
    </div>
</template>

<script>
export default {
  data() {
    return {
      success: '0'
    }
  },
  created() {
    console.log(this.$route.query)
    this.success = this.$route.query.success
  },
  methods: {
    handleClose() {
      window.open('', '_self', '')
      window.close()
    }
  }
}
</script>

<style lang="scss" scoped>
  .main{
    /*border-radius: 15px;*/
    /*background: white;*/
    width: 100%;
    height: calc(100vh - 40px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    .img{
      width: 621px;
      height: 407px;
      object-fit: contain
    }
    .text-1{
      font-size: 28px;
      color: #333333
    }
    .text-2{
      font-size: 20px;
      color: #464646
    }
    .btn{
      padding: 10px 50px;
      margin-top: 10px;
    }
    @media screen and (max-width: 500px) {
      .img{
        width: 100%;
        height: 165px;
      }
      .text-1{
        font-size: 18px ;
        color: #333333 ;
      }
      .text-2{
        font-size: 12px ;
        color: #464646 ;
      }
      .btn{
        padding: 5px 20px ;
        margin-top: 10px ;
        font-size: 12px ;
      }
    }
  }
</style>
